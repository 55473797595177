import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { TableApiService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/services/table-api.service';
import { IShip } from '../interface/ship.interface';

@Injectable()
export class ShipsService extends TableApiService<IShip> {
  constructor(http: HttpClient) {
    super(http, `${environment.cprServiceApi}ship/`);
  }
}
